<template>
  <van-nav-bar
    :title="title"
  />
  <van-grid :column-num="3" style="margin:7px 0 0 0;">
    <van-grid-item @click="onChange('WAIT_SETTLE')">
      <p>
        <small>{{ v1 }} 元</small>
      </p>
      <p>
        <small>待结算</small>
      </p>
    </van-grid-item>
    <van-grid-item @click="onChange('SETTLED')">
      <p>
        <small>{{ v2 }} 元</small>
      </p>
      <p>
        <small>已结算</small>
      </p>
    </van-grid-item>
    <van-grid-item @click="onChange('CLOSED')">
      <p>
        <small>{{ v3 }} 元</small>
      </p>
      <p>
        <small>已关闭</small>
      </p>
    </van-grid-item>
  </van-grid>
  <van-list
    ref="dropdownRef"
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in items" :key="item">
      <div style="background:#fff;padding:15px;margin:7px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>收益 &yen;{{ item.amount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>备注 {{ item.memo }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>状态 {{ item.statusText }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>时间 {{ item.createTime }}</small>
        </p>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject, ref } from 'vue'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      items: [],
      title: '',
      userId: Cookies.get('userId'),
      v1: 0,
      v2: 0,
      v3: 0,
      status: 'WAIT_SETTLE'
    })
    const dropdownRef = ref(null)
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const onChange = (status) => {
      console.log(status)
      // console.log(dropdownRef)
      state.status = status
      reset()
      dropdownRef.value.check()
    }
    const getData = () => {
      post('/commission.list', {
        pageNum: state.page,
        userId: state.userId,
        status: state.status
      }).then(res => {
        state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    const sum = () => {
      post('/commission.sum', {
        userId: state.userId,
        status: 'WAIT_SETTLE'
      }).then(res => {
        state.v1 = res.data.amount
      })
      post('/commission.sum', {
        userId: state.userId,
        status: 'SETTLED'
      }).then(res => {
        state.v2 = res.data.amount
      })
      post('/commission.sum', {
        userId: state.userId,
        status: 'CLOSED'
      }).then(res => {
        state.v3 = res.data.amount
      })
    }
    const init = () => {
      sum()
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onChange,
      dropdownRef
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
